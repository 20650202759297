import React from 'react'
import Layout from '../../components/layout'
import PhotoshootPackage from '../../components/photoshoot-package'
import style from '../prices.module.css'

import { graphql } from 'gatsby'
import { get } from 'lodash'
import { Helmet } from 'react-helmet'

class Events extends React.Component {
  render() {
    const packageType = get(this, 'props.data.contentfulPackageType')

    const packages = get(
      this,
      'props.data.allContentfulPhotoshootPackage.nodes',
      []
    )

    const footnotesHtml = get(
      this,
      'props.data.contentfulGeneral.content.childMarkdownRemark.html'
    )

    return (
      <Layout
        location={this.props.location}
        packageTypes={this.props.data.allContentfulPackageType}
        photoshoots={this.props.data.contentfulEntriesGroup}
      >
        <>
          <Helmet>
            <title>Juliana de Souza Photography</title>

            <meta
              name="description"
              content="Check out the services provided by Juliana de Souza"
            />
          </Helmet>

          <div className={style.lead}>
            <h1 className={style.leadTitle}>{packageType.name}</h1>

            <div
              dangerouslySetInnerHTML={{
                __html: packageType.description.childMarkdownRemark.html,
              }}
            />
          </div>

          <div className={style.packages}>
            {packages.map((photoPackage) => (
              <PhotoshootPackage
                key={photoPackage.id}
                photoPackage={photoPackage}
              />
            ))}
          </div>

          <div
            className={style.remark}
            dangerouslySetInnerHTML={{ __html: footnotesHtml }}
          />
        </>
      </Layout>
    )
  }
}

export default Events

export const pageQuery = graphql`
  query PackageType($slug: String) {
    allContentfulPackageType {
      nodes {
        name
        slug
      }
    }

    contentfulPackageType(slug: { eq: $slug }) {
      name
      description {
        childMarkdownRemark {
          html
        }
      }
    }

    contentfulEntriesGroup(type: { eq: "Photoshoot" }) {
      items {
        name
        slug
      }
    }

    allContentfulPhotoshootPackage(
      sort: { fields: createdAt, order: ASC }
      filter: { type: { slug: { eq: $slug } } }
    ) {
      nodes {
        id
        name
        details
        price
      }
    }

    contentfulGeneral(type: { eq: "package-footnotes" }) {
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
