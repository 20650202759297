import React from 'react'
import style from './photoshoot-package.module.css'

export default ({ photoPackage }) => (
  <div className={style.package}>
    <div>
      <span className={style.name}>{photoPackage.name}</span>

      {photoPackage.details && (
        <ul className={style.list}>
          {photoPackage.details.map((item) => (
            <li className={style.item}>{item}</li>
          ))}
        </ul>
      )}
    </div>

    <span className={style.price}>€ {photoPackage.price},-</span>
  </div>
)
